import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useMemo } from 'react'

import '../../styles/global.css'

import tip1CzImg from '../../assets/tips/cz/tip1.png'
import tip2CzImg from '../../assets/tips/cz/tip2.png'
import tip3CzImg from '../../assets/tips/cz/tip3.png'
import tip4CzImg from '../../assets/tips/cz/tip4.png'
import tip5CzImg from '../../assets/tips/cz/tip5.png'
import tip1Img from '../../assets/tips/tip1.png'
import tip2Img from '../../assets/tips/tip2.png'
import tip3Img from '../../assets/tips/tip3.png'
import tip4Img from '../../assets/tips/tip4.png'
import tip5Img from '../../assets/tips/tip5.png'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SectionStores } from '../../components/section-stores'
import { SEO } from '../../components/seo'

const Tips = () => {
  const { t, i18n } = useTranslation('pages', { keyPrefix: 'product.tips' })

  const isCzech = useMemo(() => i18n.language === 'cs', [i18n.language])

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage isFull>
            <div className="grid grid-cols-12 gap-y-12 lg:gap-x-24 my-20">
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <h2>{t('1.title')}</h2>
                <p>{t('1.text')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 flex justify-center">
                <img src={isCzech ? tip1CzImg : tip1Img} alt="tip 1" />
              </div>
              <div className="col-span-12 bg-gray-200 w-full h-1" />
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <h2>{t('2.title')}</h2>
                <p>{t('2.text')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 flex justify-center">
                <img src={isCzech ? tip2CzImg : tip2Img} alt="tip 2" />
              </div>
              <div className="col-span-12 bg-gray-200 w-full h-1" />
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <h2>{t('3.title')}</h2>
                <p>{t('3.text1')}</p>
                <p>{t('3.text2')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 flex justify-center">
                <img src={isCzech ? tip3CzImg : tip3Img} alt="tip 3" />
              </div>
              <div className="col-span-12 bg-gray-200 w-full h-1" />
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <h2>{t('4.title')}</h2>
                <p>{t('4.text1')}</p>
                <p>{t('4.text2')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 flex justify-center">
                <img src={isCzech ? tip4CzImg : tip4Img} alt="tip 4" />
              </div>
              <div className="col-span-12 bg-gray-200 w-full h-1" />
              <div className="col-span-12 lg:col-span-5 lg:place-self-center">
                <h2>{t('5.title')}</h2>
                <p>{t('5.text1')}</p>
                <p>{t('5.text2')}</p>
              </div>
              <div className="col-span-12 lg:col-span-7 flex justify-center">
                <img src={isCzech ? tip5CzImg : tip5Img} alt="tip 5" />
              </div>
            </div>
          </ContentSubPage>
          <SectionStores className="pt-8 pb-28" />
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default Tips

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
